<template>
  <v-icon
    v-if="row.templateType==row.n_id"
    title="Project Active Template"
    color="green"
  >mdi-checkbox-blank-circle</v-icon>
  <v-icon v-else color="blue">mdi-checkbox-blank-circle</v-icon>
</template>
<script>
export default {
  props: ["row"],
};
</script>